<template>
    <div>
        <!-- CONTRATOS PENDIENTES POR FIRMAR -->
        <div v-if="isMounted">
            <div v-if="groupedContracts.length > 0" class="vx-row mt-base">
                <div class="vx-col w-full">
                    <big-title-color variant-class="clear">
                    <template v-slot:text>Firma de <span>Contratos</span></template>.
                    </big-title-color> 
                    <div class="vx-row">
                      <div class="vx-col md:w-full xxl:w-1/3">
                        <vx-card class="mt-base" title="¡Es hora de firmar!">
                        <p class="mt-2">
                            Para comenzar con el proceso deberás
                            seguir las instrucciones que se 
                            encuentran a continuación y corroborar 
                            la información sobre los involucrados en 
                            la firma. No olvides que este proceso 
                            deberás realizarlo únicamente en una 
                            tablet o teléfono móvil. Considera que 
                            tendrás únicamente 5 minutos para realizarlo 
                            y un máximo de 3 intentos. Ten a la mano 
                            tu identificación oficial, buena conexión 
                            a internet y cámara en tu móvil o tablet.<br>
                            <br>
                            <br>
                            Para cualquier duda contáctanos a<br>
                            través del chat de la plataforma.
                            <br>
                            <br>
                            <br>
                            <br>
                        </p>
                        </vx-card>
                      </div>
                      <div class="vx-col w-full md:w-full xxl:w-2/3">
                          <h1 class="extrabold text-4xl mt-base">
                          <youtube-video-widget :media="videoFad" title="¿Cómo firmar con FAD?"/>
                          </h1>
                      </div>

                    <div class="vx-col w-full">
                        <vx-card class="mt-base" title="¡Es hora de firmar!">
                        <p>
                            Para comenzar con el proceso deberás seguir las instrucciones que se encuentran a continuación y corroborar la información sobre los involucrados 
                            en la firma. No olvides que este proceso deberás realizarlo únicamente en una tablet o teléfono móvil. Considera que tendrás únicamente 5 minutos 
                            para realizarlo y un máximo de 3 intentos. Ten a la mano tu identificación oficial, buena conexión a internet y cámara en tu móvil o tablet.
                            <br>                            
                            Para cualquier duda contáctanos a través del chat de la plataforma.
                        </p>
                        </vx-card>
                    </div>
                    <!-- <div class="vx-col w-full lg:w-2/3">
                        <h1 class="extrabold text-4xl mt-base">
                        <youtube-video-widget :media="videoFad" title="¿Cómo llenar la solicitud?"/>
                        </h1>
                    </div> -->
                    </div>
                    <template v-for="(group, index) in groupedContracts">
                    <vx-card class="mt-base" :key="index" v-if="group.length != 0">
                        <div class="vx-row mb-2" >
                            <div class="vx-col w-full">
                            <div class="flex">
                                <p class="card-sub-title">Grupo {{group[0].group.name}}.</p>
                                <vs-chip v-if="group[0].status === 'generated'"
                                class="text-md bold ml-4"
                                color="dark"> No disponible
                                </vs-chip>
                                <vs-chip v-else-if="group[0].status === 'sent'"
                                class="text-md bold ml-4"
                                color="danger"> Pendiente
                                </vs-chip>
                                <vs-chip v-else
                                class="text-md bold ml-4"
                                color="success"> Firmado
                                </vs-chip>
                            </div>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">
                              <p v-if="group[0].status === 'generated'">
                                  Este contrato aún no está disponible para firmarse. Por el momento solo podrás leerlo si así lo deseas.
                              </p>
                              <p v-else-if="group[0].status === 'sent'">
                                  Para comenzar con el proceso deberás seguir las instrucciones que se encuentran a continuación y corroborar la información
                                  sobre los involucrados en la firma. Si deseas consultarlo antes de firmar, da clic en el botón “Descargar”.
                              </p>
                              <p v-else>
                                  Este contrato ha sido firmado con éxito. Para consultarlo da clic en el botón “Consultar”.
                              </p>
                              <vs-button v-if="group[0].status === 'signed'" type="border" class="mt-5" target="blank" :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${group[0].id}/${group[0].contract_signed_file}?access_token=${access_token}`)">Consultar</vs-button>
                              <vs-button v-else type="border" class="mt-5" target="blank" :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${group[0].id}/${group[0].contract_not_signed_file}?access_token=${access_token}`)">Descargar</vs-button>
                              <div v-if="group[0].status === 'sent'">
                                  <template v-for="(signatory, indexS) in handleSignatories(group[0])">
                                  <div v-if="signatory.type != 'supplier' && signatory.type != 'redgirasol'" :key="indexS" class="mt-5">
                                      <h5>{{getRoles(signatory.type)}}</h5>
                                      <div class="vx-row mt-4">
                                      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
                                          <p class="pl-3">Nombre</p>
                                          <p class="info-border-inline mb-2">{{signatory.name != null && signatory.name != "" ? signatory.name : 'Sin información'}}</p>
                                      </div>
                                      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
                                          <p class="pl-3">Teléfono</p>
                                          <p class="info-border-inline mb-2">{{ signatory.telephone != null && signatory.telephone != "" ? phoneFormat(signatory.telephone) : "Sin información" }}</p>
                                      </div>
                                      </div>
                                      <div class="vx-row mt-3">
                                      <div class="vx-col w-full">
                                          <p>Para la <strong>firma de {{getRoles(signatory.type)}}</strong> da clic en el botón 'Abrir liga' en tu <strong>teléfono móvil</strong> o cópiala en el navegador del mismo.</p>                
                                          <div class="flex items-center mt-2">
                                            <vs-button class="mb-2 mr-3" @click.stop="handleFadLink(signatory.link)">Abrir liga</vs-button>
                                            <vs-button class="mb-2" @click.stop="copyToClipboard(signatory.link)">Copiar liga</vs-button>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  </template>
                              </div>
                            </div>
                        </div>
                    </vx-card>
                    </template>
                </div>
            </div>
            <vx-card v-else style="padding: 4.5rem;" class="text-center w-full">
                <img src="@/assets/images/figs/fig9.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">        
                <h2 class="mb-6 mt-6">En estos momentos no tienes
                                <br>contratos pendientes por
                                <br>firmar.</h2>
                <vs-button @click="goToHomePage()">Volver al inicio</vs-button>
            </vx-card>
        </div>
    </div>
</template>

<script>
import YoutubeVideoWidget from '@components/supplier/widgets/YoutubeVideoWidget.vue';
import formatHelper from '@mixins/formatHelper';
const tutorialFADLink = "https://youtu.be/19npgHmTUi0";
const FADLinkBaseDevelopment = "https://uatwebfad.firmaautografa.com/signList?ticketReq=";
const FADLinkBaseProduction = "https://mobile.firmaautografa.com/main?ticket=";
export default {
  name: "ApplicantPendingContracts",
  mixins: [formatHelper],
  props: ["pid"],
  components:{
    YoutubeVideoWidget,
  },
  async mounted(){
    this.initComponent();
  },
  data() {
    return {
        isMounted: false,
        pendingContracts: [],
        groupedContracts: [],
        FADLinkBase: process.env.VUE_APP_ENV == "production" ? FADLinkBaseProduction : FADLinkBaseDevelopment,
        videoFad: { sources: [{ type: 'video/youtube', src: tutorialFADLink }], poster: 'https://cdn.redgirasol.com/green/COVERS_VIDEOS/firma_autografa.png' },
    }
  },
  methods: {
    async initComponent(){
      this.isMounted = false
      this.showLoading(true)
      const auth = await this.authorizeViewProject();
      if(!auth){
        await this.$router.replace({name: 'inicioSolicitante'});
        this.showLoading(false)
        return;
      }
      await this.getPendingContracts();
      this.isMounted = true
      this.showLoading(false)
    },
    async authorizeViewProject(){
      try {
        await axios.get(`api/v1/applicant/${this.ApplicantId}/authorizeViewProject/${this.pid}`);
        return true;
      }
      catch (e){
        return false;
      }
    },
    async getPendingContracts(){
      this.isMounted = false
      this.showLoading(true)
      try {
        const response = await axios.get(`api/v1/applicant/${this.ApplicantId}/getPendingContracts/${this.pid}`);
        this.pendingContracts = response.data;
        if (this.pendingContracts.length > 0) {
          this.groupContracts();
        }
      }
      catch (e){
        this.warn(e);
      }
      this.isMounted = true
      this.showLoading(false)
    },
    groupContracts(){
      let g1 = [];
      let g2 = [];
      let g3 = [];
      let g4 = [];
      let generic = [];
      this.pendingContracts.forEach(contract => {
        if (contract.signatories) {
          switch(contract.contract_group_id){
            case 1: g1.push(contract); break;
            case 2: g2.push(contract); break;
            case 3: g3.push(contract); break;
            case 4: g4.push(contract); break;
            default: 
              generic.push(contract); 
              this.hasOldContracts = true;
          }
        }
      });
      this.groupedContracts = [g1, g2, g3, g4, generic];
    },
    handleSignatories(data){
      let requisition = JSON.parse(data.fad_requisition);
      let allTickets = Object.values(requisition.allTickets);
      let arraySignatories = JSON.parse(data.signatories);
      if (arraySignatories != null){ 
          arraySignatories.forEach(signatory => {
            signatory.link = allTickets.find(ticket => ticket.type === signatory.type);
          });
         return arraySignatories;
      }
      else {
        return [];
      }   
    },
    getRoles(role){
      let r = "";
      switch(role){
        case "customer":
          r = "Solicitante";
        break;
        // case "supplier":
        //   r = "Proveedor";
        // break;
        // case "redgirasol":
        //   r = "RedGirasol";
        // break;
        default: r = "Obligado Solidario";
      }
      return r;
    },
    handleFadLink(ticket){
      window.open(this.FADLinkBase + ticket, '_blank');
    },
    copyToClipboard(ticket){
      const el = document.createElement('textarea');
      el.value = this.FADLinkBase + ticket;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotif("Liga copiada al portapapeles");
    },
    async goToHomePage(){
        await this.$router.push({name: 'inicioSolicitante'});
    },
  }
}
</script>